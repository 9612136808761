export const evolucional_redirect_url = 'https://simulados.evolucional.com.br/sair';
export const nitro_url = 'https://nitro.evolucional.com.br';
export const listas_url = 'https://listas.evolucional.com.br';
export const defaultModalSize = '80%';
export const defaultModalHeight = '60vh';

export const DEFAULT_PER_PEGE = 10;
export const DEFAULT_PER_PAGE = DEFAULT_PER_PEGE;

export const DEFAULT_SORTABLE_ICON = 'fas fa-sort-up'

export const DEFAULT_DIALOG_WIDTH = "80vw"

export const TYPE_MODULE = 'module';

export const DEFAULT_DIALOG_SCROLL_HEIGHT = "75vh"

export const DEVELOP_DOMAINS = ['localhost','aura.pc4.com.br','nitro.test','nitro-admin.test','nitro-admin.pc4.com.br']

export const DEFAULT_DATETIME_FORMAT = "DD/MM/yyyy HH:mm"

/** Common table cell widths */
export const DEFAULT_MODULE_NAME_TABLE_CELL_WIDTH = 300;
export const DEFAULT_SUBJECT_NAME_TABLE_CELL_WIDTH = 220;
export const DEFAULT_USER_NAME_TABLE_CELL_WIDTH = 300;
export const DEFAULT_USER_IDENTIFIER_TABLE_CELL_WIDTH = 100;

export const isInDevelop = (function name() {
  return DEVELOP_DOMAINS.includes(window.location.host) || DEVELOP_DOMAINS.includes(window.location.host.split(':')[0])
})()

export const isNotInDevelop = (function name() {
  return !DEVELOP_DOMAINS.includes(window.location.host) || !DEVELOP_DOMAINS.includes(window.location.host.split(':')[0])
})()

import moment from "moment"

export const VACATION_PERIODS = [
  ["2022/07/02","2022/08/07"]
]

export const  IS_ON_VACATION =  (() => VACATION_PERIODS.map((dates) => moment().isBetween(dates[0], dates[1]) ? 1 : 0 ).reduce((a,b) => a + b))();

export const DEFAULT_MODAL_OPACITY = '0.9';

