<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :style="`overflow-y: auto; overflow-x:hidden;max-height: ${defaultModalHeight}`"
    class="p-4 bg-white module-question-component position-relative"
  >
    <div
      v-for="(question, index) in questions"
      :key="index"
    >
      <div>
        <span class="text-warning">({{ index + 1 }})</span>
        {{ question.description }}
      </div>
      <div
        class="mt-2"
        v-html="question.content"
      />
      <div
        v-for="(choice, key) in question.choices"
        :key="key"
        class="d-flex rounded my-2 position-relative"
      >
        <span class="position-absolute text-uppercase font-weight-bold">
          {{ alternatives[key] }}: 
        </span>

        <div
          class="ml-3 choice-content-container"
          v-html="choice.content"
        />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    questions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      defaultModalHeight: "60vh",
      alternatives:['a','b','c','d','e']
    }
  }
}
</script>
