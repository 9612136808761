<template>
  <el-row
    :gutter="20"
    class="my-2"
  >
    <el-col :md="4">
      <el-button
        v-show="!showSelectVideo"
        class="w-100"
        type="info"
        @click="onSelectVideo"
      >
        Adicionar vídeo
      </el-button>
      <el-select
        v-show="showSelectVideo"
        ref="selectVideo"
        v-model="video"
        filterable
        clearable
        placeholder="Selecione um video"
        class="w-100"
        @blur="showSelectVideo = false"
        @change="add(video)"
      >
        <el-option
          v-for="item in videos"
          :key="item.titleText"
          :label="item.titleText"
          :value="item"
        />
      </el-select>
    </el-col>
    <el-col :md="4">
      <el-button
        v-show="!showSelectPage"
        class="w-100"
        @click="onSelectPage"
      >
        Adicionar página
      </el-button>
      <el-select
        v-show="showSelectPage"
        ref="selectPage"
        v-model="page"
        filterable
        clearable
        placeholder="Selecione uma página"
        class="w-100"
        @blur="showSelectPage = false"
        @change="add(page)"
      >
        <el-option
          v-for="item in pages"
          :key="item.titleText"
          :label="item.titleText"
          :value="item"
        />
      </el-select>
    </el-col>
    <el-col :md="4">
      <el-button
        v-show="!showSelectQuiz"
        class="w-100"
        type="success"
        @click="onSelectQuiz"
      >
        Adicionar questionário
      </el-button>
      <el-select
        v-show="showSelectQuiz"
        ref="selectQuiz"
        v-model="quiz"
        filterable
        clearable
        placeholder="Selecione um questionário"
        class="w-100"
        @blur="showSelectQuiz = false"
        @change="add(quiz)"
      >
        <el-option
          v-for="item in quizzes"
          :key="item.titleText"
          :label="`${item.titleText} - ${item.module ? item.module.name : ''}`"
          :value="item"
        />
      </el-select>
    </el-col>
  </el-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      showSelectVideo: false,
      showSelectPage: false,
      showSelectQuiz: false,
      video: '',
      page: '',
      quiz: '',
    }
  },
  computed: {
    ...mapGetters(['videos','subjects','pages','quizzes']),
  },
  methods: {
    ...mapActions(['getVideos', 'getPages','getQuizzes']),
    add(item) {
      this.showSelectVideo = false;
      this.showSelectPage = false;
      this.showSelectQuiz = false;
      this.$emit('add', item);
      this.video = '';
      this.page = '';
      this.quiz = '';
    },
    onSelectVideo() {
      if (!this.videos.length) {
        this.getVideos()
      }
      this.showSelectVideo = true;
      this.showSelectPage = false;
      this.showSelectQuiz = false;
      this.$nextTick(() => this.$refs.selectVideo.focus())
    },
    onSelectPage() {
      if (!this.pages.length) {
        this.getPages()
      }
      this.selectVideo = false;
      this.showSelectPage = true;
      this.showSelectQuiz = false;
      this.$nextTick(() => this.$refs.selectPage.focus());
    },
    onSelectQuiz() {
      if (!this.quizzes.length) {
        this.getQuizzes()
      }
      this.selectPage = false;
      this.selectVideo = false;
      this.showSelectQuiz = true;
      this.$nextTick(() => this.$refs.selectQuiz.focus());
    }
  }
}
</script>
