export const interactions =
 {
    "accessed_challenge": "Acessou um desafio",
    "accessed_module": "Acessou módulo",
    "accessed_theme": "Acessou tema",
    "finished_challenge": "Concluiu um desafio",
    "finished_module": "Finalizou módulo",
    "finished_theme": "Finalizou tema",
    "logged": "Logou na plataforma",
    "set_trail": "Selecionou trilha",
    "started_challenge": "Iniciou o desafio",
    "created_schedule": "Cadastrou seu horário de estudos",
    "set_modules": "Selecionou os módulos",
    "accessed_metrics": "Acessou os relatórios",
    "accessed_module_metrics": "Acessou os relatórios de módulos",
    "accessed_question_metrics": "Acessou os relatórios de questões",
    "accessed_calendar": "Acessou o calendário",
    "has_challenge_finished": "Iniciou o desafio, mas não finalizou a avaliação de forma correta",
}

export const subinteractions = {
  "accessed_scorm": "Acessou scorm",
  "finished_scorm": "Finalizou scorm",
  "accessed_video": "Acessou vídeo",
  "finished_video": "Finalizou vídeo",
  "acessed_video": "Acessou vídeo",
  "finished_link": "Finalizou vídeo",
  "accessed_quiz": "Acessou questionário",
  "finished_quiz": "Acessou questionário",
  "accessed_ebook": "Acessou ebook",
  "answered_challenge_question": "Respondeu a questão",
}

export const contentTypes =  {
  "video": "Vídeo",
  "quiz": "Quiz",
  "page": "Página",
}

export const contentColors =  {
  "video": "info",
  "quiz": "warning",
  "page": "primary",
}



export default interactions
