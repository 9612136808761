
import SchoolCycleReportPage from "@/admin/Pages/reports/school-cycle/Index";
export const SchoolCycleReportIndexRoute = {
    path: "/administrativo/report/school-cycle-report",
    component: SchoolCycleReportPage,
    name: 'school-cycle-report',
    meta: {
     label: 'Relatório Escolas por ciclo',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}

import EngagementPage from "@/admin/Pages/reports/engagement/Index";

export const EngagementIndexRoute = {
    path: "/administrativo/report/engagement",
    component: EngagementPage,
    name: 'engagement-report',
    meta: {
     label: 'Relatório de engajamento',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}

import StudentGlobalReportPage from "@/admin/Pages/reports/student-global/Index";
export const StudentGlobalReportRoute = {
    path: "/administrativo/report/student-global-report",
    component: StudentGlobalReportPage,
    name: 'student-global-report',
    meta: {
     label: 'Relatório Geral',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}

import StudentsProgressReportPage from "@/admin/Pages/reports/students-progress/Index";
export const StudentsProgressReportRoute = {
    path: "/administrativo/report/student-progress",
    component: StudentsProgressReportPage,
    name: 'students-progress',
    meta: {
     label: 'Relatório Progresso',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}

import StudentPerTrailReportPage from "@/admin/Pages/reports/students-per-trail/Index";
export const StudentsPerTrailReportRoute = {
    path: "/administrativo/report/students-per-trail",
    component: StudentPerTrailReportPage,
    name: 'students-per-trail',
    meta: {
     label: 'Relatório Áreas de interesse',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}

export default [
    SchoolCycleReportIndexRoute,
    StudentGlobalReportRoute,
    StudentsPerTrailReportRoute,
    StudentsProgressReportRoute,
    EngagementIndexRoute,
]