
export default {
    state: {
        list: [],
        current: {
          subject_id: null,
          default: 'default',
          module_id: null,
          questions: [],
          questions_ids: [],
        },
        isLoadingQuizzes:false,
    },
    actions: {
        getQuizzes({commit,state}, params = '') {
            state.isLoadingQuizzes = true;
            return axios.get(`/admin/quiz?${params}`)
            .then(r => {
                commit("SET_QUIZZES", r.data.data);
                return r;
            })
              .finally(()=>state.isLoadingQuizzes = false);
        },
        getQuiz({commit}, params) {
            return axios.get(`/admin/quiz/${params.data.id}`)
            .then(r => {
                commit("SET_QUIZ", r.data.data);
                return r
            });
        },
        createQuiz({commit}, { data }) {
            return axios.post(`/admin/quiz`, data);
        },
        updateQuiz({commit}, params) {
            return axios.put(`/admin/quiz/${params.id}`, params.data);
        },
        deleteQuiz({commit}, params) {
            return axios.delete(`/admin/quiz/${params.id}`,{
              headers: {
                "Content-Type": `multipart/form-data`
              }
            });
      },
    },
    mutations: {
        SET_QUIZZES(state, quizzes) {
            state.list = quizzes.map(quiz=> ({
                questions_ids: quiz.questions.map(question => question.id),
                type: 'quiz',
                titleText: `ID ${quiz.id} - ${quiz.title}`,
                tagType: 'warning',
                ...quiz,
            }));
        },
        SET_QUIZ(state, payload) {
            state.current = payload;
        }
    },
    getters:{
        quizzes: state => state.list,
        quiz: state => state.current,
        isLoadingQuizzes: state => state.isLoadingQuizzes,
    }

};
