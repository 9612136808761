import VideoPage from "@/admin/Pages/video/Index";
export const VideoIndexRoute = {
    path: "/administrativo/video",
    component: VideoPage,
    name: 'view-video',
    meta: {
     label: 'Vídeos',
     iconClass: 'fas fa-chart-bar',
     category: 'content'
    }
}

import ChallengeQuestionPage from "@/admin/Pages/challenge-question/Index";
export const ChallengeQuestionIndexRoute = {
    path: "/administrativo/challenge-questions",
    component: ChallengeQuestionPage,
    name: 'view-challenge-question',
    meta: {
        label: 'Questões desafio',
        iconClass: 'fas fa-chart-bar',
        category: 'content',
    }
}

import ChallengePage from "@/admin/Pages/challenge/Index";
export const ChallengeIndexRoute = {
    path: "/administrativo/challenge",
    component: ChallengePage,
    name: 'view-challenge',
    meta: {
        label: 'Desafios',
        iconClass: 'fas fa-chart-bar',
    }
}

import SubjectPage from "@/admin/Pages/subject/Index";
export const SubjectIndexRoute = {
    path: "/administrativo/subject",
    component: SubjectPage,
    name: 'view-subject',
    meta: {
     label: 'Disciplinas',
     iconClass: 'fas fa-book-open'
    }
}

import FilePage from "@/admin/Pages/file/Index";
export const FileIndexRoute = {
    path: "/administrativo/file",
    component: FilePage,
    name: 'view-file',
    meta: {
     label: 'Arquivos',
     iconClass: 'fas fa-folder-open'
    }
}

import LinkPage from "@/admin/Pages/link/Index";
export const LinkIndexRoute = {
    path: "/administrativo/link",
    component: LinkPage,
    name: 'view-link',
    meta: {
     label: 'Links úteis',
     iconClass: 'fas fa-chart-bar',
     category: 'content'
    }
}



// Página de cadastro do conteúdo de páginas
import PageIndex from "@/admin/Pages/page/Index";
export const PageIndexRoute = {
    path: "/administrativo/page",
    component: PageIndex,
    name: 'view-page',
    meta: {
     label: 'Páginas',
     iconClass: 'fas fa-chart-bar',
     category: 'content'
    }
}

// Página de cadastro de questões de questionário
import QuestionPage from "@/admin/Pages/question/Index";
export const QuestionIndexRoute = {
    path: "/administrativo/question",
    component: QuestionPage,
    name: 'view-question',
    meta: {
     label: 'Questões quiz',
     iconClass: 'fas fa-chart-bar',
     category: 'content'

    }
}


import ScormPage from "@/admin/Pages/scorm/Index";
export const ScormIndexRoute = {
    path: "/administrativo/scorm",
    component: ScormPage,
    name: 'view-scorm',
    meta: {
     label: 'Scorms',
     iconClass: 'fas fa-chart-bar',
     category: 'content'
    }
}

import EbookPage from "@/admin/Pages/ebook/Index";
export const EbookIndexRoute = {
    path: "/administrativo/ebook",
    component: EbookPage,
    name: 'view-ebook',
    meta: {
     label: 'Ebooks',
     iconClass: 'fas fa-book-reader',
     category: 'content'
    }
}

import QuizPage from "@/admin/Pages/quiz/Index";
export const QuizIndexRoute = {
    path: "/administrativo/questionarios",
    component: QuizPage,
    name: 'view-quiz',
    meta: {
     label: 'Quizzes',
     iconClass: 'fas fa-book-reader',
     category: 'content'
    }
}

export default [
    VideoIndexRoute,
    ChallengeQuestionIndexRoute,
    ChallengeIndexRoute,
    SubjectIndexRoute,
    FileIndexRoute,
    LinkIndexRoute,
    PageIndexRoute,
    QuestionIndexRoute,
    ScormIndexRoute,
    EbookIndexRoute,
    QuizIndexRoute,
]