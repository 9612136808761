<template>
  <draggable
    v-model="contents"
    @change="$emit('input', contents)"
  >
    <transition-group class="transition">
      <el-row
        v-for="(content, index) in contents"
        :key="content.title"
        class="border p-2"
      >
        <el-col :md="20">
          <el-tag size="medium">
            {{ index + 1 }}°
          </el-tag>
          {{ `ID ${content.id} - ${content.title}` | subStr(100) }}
        </el-col>
        <el-col :md="4">
          <div>
            <el-tag 
              :type="contentColors[content.type]"
              size="medium"
            >
              {{ contentTypes[content.type] }}
            </el-tag>
            <i 
              class="el-tag__close el-icon-close mx-3"
              @click="$emit('remove', content)"
            />
          </div>
        </el-col>
      </el-row>
    </transition-group>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable';
import { contentColors, contentTypes } from '@/constants/translatedInfos';

export default {
  components: {
    draggable
  },
  data(){
    return {
      contentColors,
      contentTypes,
    }
  },
  computed:{
    contents:{
      get(){
        return this.$store.state.pre_cycle_module.form.contents;
      },
      set(value){
        this.$store.state.pre_cycle_module.form.contents = value;
      }
    },
  }
}
</script>
