<template>
  <div class="mx-4">
    <el-row v-if="module.contents">
      <el-col :md="12">
        <el-carousel
          ref="slider"
          v-model="currentItem"
          :autoplay="false"
          :initial-index="activeIndex"
          trigger="click"
          arrow="never"
          height="680px"
        >
          <el-carousel-item
            v-for="(item, i) in module.contents"
            :key="i"
          >
            <el-container class="flex-column justify-content-center">
              <el-card>
                <InnerContent :content="item" />
              </el-card>
            </el-container>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :md="12">
        <el-menu
          :default-active="0"
          class="el-menu-vertical-demo"
        >
          <el-menu-item 
            v-for="(content, index) in module.contents"
            :key="content.title"
            :index="index"
            @click="$refs.slider.setActiveItem(index)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <p>{{ index + 1 }}° : {{ content.title | subStr(100) }}</p>
    
              <el-tag
                v-if="content.is_video"
                style="width:54px;text-align: center"
              >
                Vídeo
              </el-tag>
              <el-tag
                v-if="content.is_quiz"
                style="width:54px;text-align: center"
                type="danger"
              >
                Quiz
              </el-tag>
              <el-tag
                v-if="content.is_page"
                style="width:54px;text-align: center"
                type="secondary"
              >
                Página
              </el-tag>
            </div>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import InnerContent from '@/admin/Pages/module/components/content/InnerContent.vue'

export default {
  components: { InnerContent },
  props:{
    module:{
      type: Object,
      required: true,
    }
  },
  data(){
    return  {
      activeIndex: 0,
    }
  }
}
</script>
