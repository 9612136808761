export default {
    state: {
        list: [],
        current: null,
        isLoadingPages: false,
    },
    actions: {
        getPages({commit, state}, params) {
          state.isLoadingPages = true
            return axios.get(`/admin/page?${params}`)
            .then(r => {
                const  {data: { data: pages }} = r
                commit("SET_PAGES", pages);
                return r;
            })
            .finally(()=>state.isLoadingPages = false);
        },
        getPage({commit}, params) {
            return axios.get(`/admin/page/${params.data.id}`).then(r => {
                commit("SET_PAGE", r.data.data);
            });
        },
        createPage({commit}, params) {
            return axios.post(`/admin/page/`, params.data);
        },
        updatePage({commit}, params) {
            return axios.put(`/admin/page/${params.id}`, params.data);
        },
        deletePage({commit}, params){
            return axios.delete(`/admin/page/${params.id}`);
        }
    },
    mutations: {
        SET_PAGES(state, payload) {
            state.list = payload;
        },
        SET_PAGE(state, payload) {
            state.current = payload;
        }
    },
    getters: {
        pages: state =>  state.list.map(p => ({
            ...p,
            type: 'page',
            titleText: `ID ${p.id} - ${p.title}`,
            tagType: 'primary',
            module_type_text: p.module_type == 'theme' ? 'Tema' : p.module_type == 'default' ? 'Desempenho' : 'Pré-ciclo'
        })),
        page : state  =>  state.current,
        isLoadingPages: state =>  state.isLoadingPages,
    }
  };
