<template>
  <el-form
    ref="form"
    :model="form"
    :rules="FormRules"
    class="px-2 mb-2"
    label-position="top"
  >
    <el-row>
      <el-col :md="24">
        <el-form-item
          label="Nome"
          class="w-100"
          prop="name"
          :error="errors.name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="190"
            placeholder="Nome do módulo"
          />
        </el-form-item>
      </el-col>
      <el-col :md="24">
        <el-form-item
          label="Descrição"
          class="w-100"
          prop="description"
          :error="errors.description"
        >
          <el-input
            v-model="form.description"
            clearable
            name="description"
            show-word-limit
            maxlength="190"
            placeholder="Descrição do módulo"
          />
        </el-form-item>
      </el-col>

      <el-col :md="24">
        <el-form-item
          label="Bloco temático"
          prop="block_id"
          :error="errors.block_id"
        >
          <el-select
            v-model="form.block_id"
            filterable
            placeholder="Selecione o bloco temático"
            class="w-100"
          >
            <el-option
              v-for="block in theme_blocks.filter(b=> b.cycle_percentage_completion < 100 || (theme && theme.id && theme.block_id == b.id))"
              :key="block.id"
              :label="`${block.name} - ${ block.trail.name } (${block.count_themes}) (${block.cycle_percentage_completion}%)`"
              :value="block.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Disciplina"
          prop="subject_id"
          :error="errors.subject_id"
        >
          <el-select
            v-model="form.subject_id"
            filterable
            placeholder="Selecione a disciplina do módulo"
            class="w-100"
            @focus="!subjects.length ? getSubjects(): ''"
          >
            <el-option
              v-for="item in subjects"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Ordem"
          prop="order"
          :error="errors.order"
        >
          <el-select
            v-model="form.order"
            filterable
            placeholder="Selecione em qual ordem o módulo aparece"
            class="w-100"
          >
            <el-option
              v-for="item in orders"
              :key="item.value"
              :label="item.name"
              :value="String(item.value)"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item
          label="Videocast"
          prop="video_id"
          :error="errors.video_id"
        >
          <el-select
            v-model="form.video_id"
            filterable
            placeholder="Selecione o videocast do módulo"
            class="w-100"
          >
            <el-option
              v-for="item in videos"
              :key="item.id"
              :disabled="!!item.module && item.module.id != form.module_id"
              :label="item.title"
              :value="String(item.id)"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :md="24">
        <el-form-item
          label="Thumbnail"
          prop="thumbnail"
          :error="errors.thumbnail"
        >
          <input
            ref="thumbnail"
            placeholder="Selecione a thumbnail do módulo"
            class="w-100"
            type="file"
            @change="form.thumbnail = $refs.thumbnail.files.length ? true : ''"
          >
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      label="Conteúdos"
      class="w-100"
      prop="contents"
      :error="errors.contents"
    >
      <Contents
        v-model="form.contents"
        :contents="form.contents"
        @remove="removeContent"
      />
      <AddContents
        :show-add-link-button="false"
        @add="addContent"
      />
    </el-form-item>

    <p
      v-if="errors.contents"
      class="text-danger small"
    >
      {{ errors.contents }}
    </p>

    <el-row class=" mt-3">
      <el-col :md="24">
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import ThemeMixins from '@/admin/Pages/theme/components/ThemeMixins.js'
import FormRules from './FormRegisterRules.js'
import {DEFAULT_DATETIME_FORMAT} from "@/constants/general"

const orders = [
  {
    name: `Ordem 1`,
    value: '0',
  },
  {
    name: `Ordem 2`,
    value: '1',
  },
  {
    name: `Ordem 3`,
    value: '2',
  },
  {
    name: `Ordem 4`,
    value: '3',
  },
]

export default {
  mixins: [ThemeMixins],
  data() {
    return {
      FormRules,
      DEFAULT_DATETIME_FORMAT,
      orders
    }
  },
  mounted() {
    if(this.theme_blocks.length == 0){
      this.getThemeBlocks();
    }
    if(this.subjects.length == 0){
      this.getSubjects();
    }
    if(this.videos.length < 100){
      this.getVideos();
    }
  },

  methods: {
    getFormData() {
      let form = new FormData()
      for (let key in this.form) {
        form.append(key, this.form[key] ? this.form[key] : '')
      }

      form.delete('contents')
      form.append(
        'thumbnail',
        this.$refs.thumbnail.files.length ? this.$refs.thumbnail.files[0] : ''
      )
      form.delete('contents')
      this.form.contents.forEach((c, i) => {
        form.append(`contents[${i}]`, JSON.stringify(c))
      })

      return form
    },

    onSubmit() {
      this.$refs['form'].validate((valid) => {

        if (valid) {

          this.$loading()

          this.form.contents.forEach(function (array, i) {
            array.order = i
          })

          const action = this.theme ?  this.updateTheme({
            data: this.getFormData(),
            id: this.theme.id
          }) : this.createTheme({ data: this.getFormData() });

          action.then(() => {

            this.$message({
              message: 'Módulo salvo com sucesso.',
              type: 'success',
              showClose: true
            });

            this.getThemeBlocks();

            this.$emit('saved');

          })
          .catch((e) => this.showDefaultErrorMessages(e))
          .finally(()=> this.$loading().close())
        } else {
          return false
        }

      })
    },
  }
}
</script>
