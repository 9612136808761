<template>
  <el-form
    ref="form"
    :model="form"
    :rules="FormRules"
    class="container-fluid mb-2"
    label-position="top"
  >
    <el-row v-if="!form.id">
      <el-col :md="4">
        <el-form-item label="Disciplinas">
          <el-select
            v-model="form.subject_id"
            placeholder="Selecione a disciplina"
            filterable
            clearable
          >
            <el-option
              v-for="(s, index) in all_subjects"
              :key="index"
              :label="s.name"
              :value="s.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item
          label="Tipo de módulo"
          prop="module_type"
        >
          <el-select
            v-model="form.module_type"
            clearable
            placeholder="Selecione o tipo de módulo"
            filterable
          >
            <el-option
              v-for="i in module_types"
              :key="i.value+1"
              :label="i.name"
              :value="i.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item label="Módulos">
          <el-select
            v-model="form.module_id"
            clearable
            placeholder="Selecione o módulo"
            filterable
          >
            <el-option
              v-for="(m, index) in modules_list"
              :key="index"
              :label="`${m.name} (${m.type == 'theme' ? 'carreira' : 'desempenho'})`"
              :value="m.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    
    <el-row>
      <el-col>
        <el-form-item
          label="Título"
          class="w-100"
          prop="title"
          :error="errors.title"
        >
          <el-input
            v-model="form.title"
            clearable
            name="title"
            show-word-limit
            maxlength="190"
            placeholder="Título"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="quiz">
        <el-transfer
          v-model="form.questions_ids"
          style="text-align: left; display: inline-block"
          :props="{ key: 'id', label:'showTitle' }"
          :data="module_questions"
          :titles="['Questões do módulo', 'Questões do questionário']"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters } from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import FormRules from "./FormRules";

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      FormRules,
      module_types:[
        {value: 'theme', name: 'Módulo de carreira'},
        {value: 'default', name: 'Módulo de desempenho'},
        {value: 'pre_cycle', name: 'Módulos pré-ciclo'},
      ],
      module_questions: []
    }
  },
  computed: {
    ...mapGetters(['quiz','all_subjects','modules','themes','modules_and_themes','pre_cycle_modules']),
    form: {
      get() {
        return this.$store.state.quiz.current;
      },
      set(quiz) {
        this.SET_QUIZ(quiz);
      }
    },
    modules_list(){

      const list = this.form.module_type == 'theme' ? this.themes  : (this.form.module_type == 'pre_cycle' ? this.pre_cycle_modules : (this.form.module_type == 'default' ? this.modules : this.modules_and_themes))
      return this.form.subject_id ? list.filter(m => this.form.subject_id == m.subject_id) : list

    },
    filters(){
      return `&module_id=${this.form.module_id}`
    },
  },
  watch:{
    filters: {
      handler(){
        if(this.form.module_id){
          this.getQuestions()
        }
      }
    }
  },
  mounted(){
    if(this.all_subjects.length < 10 && !this.form.id){
      this.getSubjects();
    }
    if(this.modules.length<=20 && !this.form.id){
      this.getModules();
    }
    if(this.pre_cycle_modules.length<=20 && !this.form.id){
      this.getPreCycleModules();
    }
    if(this.themes.length<=20 && !this.form.id){
      this.getThemes();
    }
    if(this.form.id){
      this.getQuestions(``)
    }
  },
  methods: {
    ...mapActions(['getQuiz','createQuiz','updateQuiz']),
    ...mapActions(['getModules','getSubjects','getThemes','getPreCycleModules']),
    onSubmit() {

      this.$refs['form'].validate((valid) => {
        if (valid) {

          const action = this.quiz.id ? this.updateQuiz({
            data: this.getData(),
            id: this.quiz.id
          }) : this.createQuiz({data: this.getData()});
          this.$loading();
          action
            .then(() => {
              this.$message({
                message: 'Quiz salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=> this.showDefaultErrorMessages(e))
            .finally(()=>this.$loading().close());
          } else {
          return false;
        }
      });
    },
    setForm(quiz) {
      this.form = quiz;
    },
    getData() {
      return {
        title: this.form.title,
        module_id: this.form.module_id,
        questions: this.form.questions_ids
      };
    },
    getQuestions(){
      this.$loading();
      axios.get(`/admin/question?${this.filters}`)
      .then(response => {
        const  {data: { data: questions }} = response
          this.module_questions = questions.map(q => ({
            disabled: this.form.id ? (q.quiz_id && q.quiz_id != this.form.id) : q.quiz_id,
            ...q,
            showTitle: `${q.description} - ID ${q.id}`,
          }))
          return response
      })
      .finally(() => this.$loading().close())
    }
  },
}
</script>
<style>
.el-transfer-panel {
  width: 300px!important;
}
</style>