<template>
  <el-form
    ref="form"
    :model="form"
    :rules="FormRules"
    label-position="top"
    class="mx-4 mb-2"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Subtópico"
          prop="subtopic_id"
          :error="errors.subtopic_id"
        >
          <el-select
            v-model="form.subtopic_id"
            filterable
            class="w-100"
            placeholder="Selecione o subtópico do módulo"
            @change="onChangeSubtopic"
          >
            <el-option
              v-for="item in subtopics.filter(s=>s.id>1000)"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="name"
          :error="errors.name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="190"
            placeholder="Nome do módulo"
            :disabled="!form.subtopic_id"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Ebook"
          prop="ebook_id"
          :error="errors.ebook_id"
        >
          <el-select
            v-model="form.ebook_id"
            v-loading="isLoadingEbooks"
            filterable
            placeholder="Selecione o ebook do módulo"
            class="w-100"
            @focus="!ebooks.length ? getEbooks() : ''"
          >
            <el-option
              v-for="item in ebooks"
              :key="item.id"
              :label="`ID ${item.ebook_id} - ${item.name}`"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Disciplina"
          prop="subject_id"
          :error="errors.subject_id"
        >
          <el-select
            v-model="form.subject_id"
            filterable
            placeholder="Selecione a disciplina do módulo"
            class="w-100"
            @focus="!pre_cycle_subjects.length ? getSubjects(): ''"
          >
            <el-option
              v-for="item in pre_cycle_subjects"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item
          label="Série"
          prop="grade_id"
          :error="errors.grade_id"
        >
          <el-select
            v-model="form.grade_id"
            filterable
            placeholder="Selecione a série que o módulo estará disponível"
            class="w-100"
            @focus="!grades.length ? getGrades(): ''"
          >
            <el-option
              v-for="item in grades"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <Contents
      v-model="form.contents"
      :contents="form.contents"
      @remove="removeContent"
    />
    <AddContents @add="addContent" />
    <p
      v-if="errors.contents"
      class="text-danger small"
    >
      {{ errors.contents }}
    </p>

    <el-row class="mt-3">
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import FormRules from '@/admin/Pages/pre_cycle_module/components/FormRulesModule'
import RequestErrors from '@/admin/utils/RequestErrors'
import Contents from '@/admin/Pages/pre_cycle_module/components/Contents.vue'
import AddContents from "@/admin/components/contents/AddContents"


export default {
  components: {
    AddContents,
    Contents,
  },
  mixins: [RequestErrors],
  data() {
    return {
      FormRules,
    }
  },
  computed: {
    ...mapGetters(['ebooks','pre_cycle_module', 'pre_cycle_subjects', 'isLoadingEbooks', 'pre_cycle_module','pre_cycle_subjects','grades','subtopics']),
    form: {
      get() {
        return this.$store.state.pre_cycle_module.form
      },
      set(value) {
        this.$store.state.pre_cycle_module.form = value
      }
    },
  },
  mounted() {
    if(this.grades.length == 0 ){
      this.getGrades();
    }
    if(this.subtopics.length == 0 ){
      this.getSubtopics();
    }
    this.getEbooks();
    this.getSubjects();
  },
  methods: {
    ...mapActions([
      'getGrades',
      'getSubtopics',
      'getSubjects',
      'createPreCycleModule',
      'updatePreCycleModule',
      'getPreCycleModule',
      'getEbooks'
    ]),
    onChangeSubtopic() {
      if (!this.form.name) {
        this.form.name = this.subtopics.find(t => t.id === this.form.subtopic_id).name
      }
    },
    onSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.form.contents.forEach(function(array, i) {
            array.order = i
          })
          const form = JSON.parse(JSON.stringify(this.form))
          const action = this.pre_cycle_module
            ? this.updatePreCycleModule({
                data: form,
                id: this.pre_cycle_module.id
              })
            : this.createPreCycleModule({ data: this.form })
          action
            .then(() => {
              this.$message({
                message: 'Módulo salvo com sucesso.',
                type: 'success',
                showClose: true
              })
              this.$emit('onSave');
            })
            .catch(e => {
              this.showDefaultErrorMessages(e)
            })
        } else {
          return false
        }
      })
    },
    addContent(content) {
      let founded = false

      if (content.is_video) {
        founded = this.form.contents.find(m => m.video_id === content.id)
      }

      if (content.is_quiz) {
        founded = this.form.contents.find(m => m.quiz_id === content.id)
      }

      if (content.is_page) {
        founded = this.form.contents.find(m => m.page_id === content.id)
      }

      if (!founded) {
        this.form.contents.push(content)
      } else {
        this.$message({
          message: 'Esse item já existe na sua listagem',
          type: 'error',
          showClose: true
        })
      }
    },
    removeContent(content) {

      if (content.is_video) {
        this.form.contents = this.form.contents.filter(a => a.video_id !== content.id);
      }

      if (content.is_quiz) {
        this.form.contents = this.form.contents.filter(a => a.quiz_id !== content.id);
      }

      if (content.is_page) {
        this.form.contents = this.form.contents.filter(a => a.page_id !== content.id);
      }
    },
  }
}
</script>
