export default {
    state: {
        list: [],
        current: null,
        isLoadingVideos: false,
    },
    actions: {
        getVideos({commit, state}, params = '') {
            state.isLoadingVideos = true
            return axios.get(`/admin/video?${params}`)
            .then(r => {
              const  {data: { data: videos}} = r
              commit("SET_VIDEOS", videos);
              return r
            })
            .finally(() => state.isLoadingVideos = false);
        },
        getVideo({commit}, params) {
            return axios.get(`/admin/video/${params.data.id}`).then(r => {
                commit("SET_VIDEO", r.data.data);
            });
        },
        createVideo({commit}, params) {
            return axios.post(`/admin/video/`, params.data);
        },
        updateVideo({commit}, params) {
            return axios.put(`/admin/video/${params.id}`, params.data);
        },
        deleteVideo({commit}, params) {
            return axios.delete(`/admin/video/${params.id}`);
        }
    },
    mutations: {
      SET_VIDEOS(state, payload) {
          state.list = payload;
      },
      SET_VIDEO(state, payload) {
          state.current = payload;
      }
    },
    getters:{
        videos: state =>  state.list.map(v => ({
            ...v,
            type: 'video',
            titleText: `ID ${v.id} - ${v.title}`,
            tagType: 'info',
            module_type_text: v.module_type == 'theme' ? 'Tema' : v.module_type == 'default' ? 'Desempenho' : 'Pré-ciclo'
        })),
        video: state => state.current,
        isLoadingVideos: state => state.isLoadingVideos,
    }
};
