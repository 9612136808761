<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          :can-create="false"
        />
        <el-button @click="onConfirm('nitro')">
          Sincronizar alunos do nitro
        </el-button>
        <el-button @click="onConfirm('listas')">
          Sincronizar alunos do listas
        </el-button>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

export default {
  data() {
    return {
      registering: false,
      search: '',
      showSelectionModal:false,
    }
  },
  computed: {
    
  },
  methods: {
    onConfirm(platform){
      this.$confirm(`Tem certeza que deseja iniciar sincronia da plataforma ${platform} ? Isso pode demorar `, 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
        .then(() => {
          if(platform == 'nitro'){
            this.syncNitroUsers()
          }
          if(platform == 'listas'){
            this.syncListasUsers()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada!'
          })
        })
    },
      syncNitroUsers(){
        this.$loading()
        const url = this.isInDevelop ? 'https://aura.pc4.com.br/api/evo/sync-users' : 'https://nitro.evolucional.com.br/api/evo/sync-users';
  
        fetch(url, {method: 'POST',  mode: 'no-cors', signal: AbortSignal.timeout(1000000) , headers: {  
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"  
        }})
        .then(()=> {
          this.$message({
            message: 'Sincronização feita com sucesso',
            type: 'success'
          });
        })
        .catch(()=> {
          this.$message({
            message: 'Erro ao sincronizar usuários',
            type: 'error'
          });
        })
        .finally(()=> this.$loading().close())
      },
      syncListasUsers(){
        
        this.$loading()
        
        const url = this.isInDevelop ? 'https://nitro-listas.pc4.com.br/api/evo/sync-users' : 'https://listas.evolucional.com.br/api/evo/sync-users';
  
        fetch(url, {method: 'POST',  mode: 'no-cors', signal: AbortSignal.timeout(1000000), headers: {  
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"  
        }})
        .then(()=> {
          this.$message({
            message: 'Sincronização feita com sucesso',
            type: 'success'
          });
        })
        .catch(()=> {
          this.$message({
            message: 'Erro ao sincronizar usuários',
            type: 'error'
          });
        })
        .finally(()=> this.$loading().close())
      }
  },
}
</script>